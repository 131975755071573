import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import { registerLicense } from '@syncfusion/ej2-base';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import "../node_modules/@syncfusion/ej2-react-layouts/styles/material.css";
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

import './index.css';

import App from './App';
import Login from './Login';
import Events from './Events';
import Conductor from './Conductor';
import Conflict from './Conflict';
import Attendees from './Attendees';
import Marketing from './Marketing';
import Automation from './Automation';
import Settings from './Settings';
import Setup2FA from './Setup2fa';
import CMS from './CMS';
import Help from './Help';

registerLicense('ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkRiWntbcXFST2le');

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/events", element: <Events /> },
  { path: "/conductor", element: <Conductor /> },
  { path: "/attendees", element: <Attendees /> },
  { path: "/conflict", element: <Conflict /> },
  { path: "/marketing", element: <Marketing /> },
  { path: "/automation", element: <Automation /> },
  { path: "/settings", element: <Settings /> },
  { path: "/cms", element: <CMS /> },
  { path: "/help", element: <Help /> },
  { path: "/2fa_setup", element: <Setup2FA /> },
  { path: "/login", element: <Login /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
