import { getAuthToken } from "./browser_state";
import { getProfile } from "./profile";
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const createReferral = async ({ name, email }) => {
    const profile = await getProfile();

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const body = new FormData();
    body.append("profile_id", profile.url);
    body.append("referral_name", name);
    body.append("referral_email", email);

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/profile_referrals/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const getReferrals = async ({ profile_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_referrals/?profile_id=${profile_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })

    const referrals = await response.json();

    return referrals;
}