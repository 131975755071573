import React, { useEffect, useState } from 'react';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import * as ReactDOM from 'react-dom';

import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
 CheckBoxComponent,
 RadioButtonComponent,
 ButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import { useForm, Controller } from "react-hook-form";

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  theme
} from '@chakra-ui/react'

import { useToast } from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn, register } from './lib/authentication';
import { getConfiguration, updateConfiguration } from './lib/configuration';
import {
  getEventFormats, createEventFormat, deleteEventFormat,
  getEventLocations, createEventLocation, deleteEventLocation,
  getEventRegions, createEventRegion, deleteEventRegion,
  getVenues, createVenue, deleteVenue, getEvents
} from './lib/events';
import {
  getProfileIndustries, createProfileIndustry, deleteProfileIndustry,
  getProfileSubscriptionTypes, createProfileSubscriptionType, deleteProfileSubscriptionType,
  getMemberships,
  deleteMembership,
  createMembership,
} from './lib/profile';
import { getCreditRules, saveCreditRules } from './lib/credit_rules';

const settingsForm = {
  name: {
    label: "Name",
    type: "text",
    placeholder: "Name of Selwyn Instance",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  logo: {
    label: "Logo",
    type: "file",
    placeholder: "URL of instance logo",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  header_logo: {
    label: "Header Logo",
    type: "file",
    placeholder: "URL of header logo",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
	description: {
    label: "Description",
    type: "textarea",
    placeholder: "Description of Selwyn Instance, Intro Text",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  email: {
    label: "Contact Email",
    type: "text",
    placeholder: "Contact Email",
    defaultValue: ""
  },
	primaryColor: {
    label: "Styling: Primary Colour",
    type: "text",
    placeholder: "#000000",
    defaultValue: ""
  },
  secondaryColour: {
    label: "Styling: Primary Colour",
    type: "text",
    placeholder: "#000000",
    defaultValue: ""
  },
  registration_enabled: {
    label: "Registration Enabled",
    type: "checkbox",
    defaultValue: true
  },
  require_coupon: {
    label: "Require Coupon",
    type: "checkbox",
    defaultValue: false
  },
  portal_url: {
    label: "Portal URL",
    type: "text",
    placeholder: "Portal URL",
  },
  terms: {
    label: "Terms and Conditions",
    type: "textarea",
    placeholder: "Terms and Conditions",
  },
	customCSS: {
    label: "Styling: CustomCSS",
    type: "textarea",
    placeholder: ".body {}",
    defaultValue: ""
  },
  customJS: {
    label: "Styling: Custom Javascript",
    type: "textarea",
    placeholder: "() => {}",
    defaultValue: ""
  }
}

const formatsForm = {
  name: {
    label: "Format Name",
    type: "text",
    placeholder: "Name of Meeting Format",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  description: {
    label: "Format Description",
    type: "text",
    placeholder: "Decription of Format",
    defaultValue: "",
    rules: {
      required: true,
    }
  }
}; 

const locationsForm = {
  name: {
    label: "Location Name",
    type: "text",
    placeholder: "Name of Meeting Locaiton",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  description: {
    label: "Location Address",
    type: "text",
    placeholder: "Address of Location",
    defaultValue: "",
    rules: {
      required: true,
    }
  }
}; 

const regionsForm = {
  name: {
    label: "Region Name",
    type: "text",
    placeholder: "Name of Meeting Region",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  description: {
    label: "Region Description",
    type: "text",
    placeholder: "Description of Region",
    defaultValue: "",
    rules: {
      required: true,
    }
  }
};

const industriesForm = {
  name: {
    label: "Industry Name",
    type: "text",
    placeholder: "Name of Industry",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  description: {
    label: "Industry Description",
    type: "text",
    placeholder: "Description of Industry",
    defaultValue: "",
    rules: {
      required: true,
    }
  }
};

const membershipsForm = {
  type: {
    label: "Membership Type",
    type: "dropdown",
    placeholder: "Type of Membership",
    options: ["Monthly", "Event"],
    defaultValue: "Monthly",
    rules: {
      required: true,
    },
  },
  name: {
    label: "Membership Name",
    type: "text",
    placeholder: "Name of Membership",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  description: {
    label: "Membership Description",
    type: "text",
    placeholder: "Description of Membership",
    defaultValue: "",
    rules: {
      required: true,
    }
  },
  cost: {
    label: "Membership Cost",
    type: "number",
    placeholder: "Monthly Cost of Membership",
    defaultValue: "10",
    rules: {
      required: true,
    }
  },
  monthly_credits: {
    label: "Monthly Credits",
    type: "number",
    placeholder: "Number of Credits per Month",
    defaultValue: "1",
    rules: {
      required: true,
    }
  },
  event: {
    label: "Event",
    type: "dropdown",
    placeholder: "Event Membership",
    options: [],
    defaultValue: "",
    rules: {
      required: false,
    },
    visibility: "hidden",
    visibilityTrigger: "type",
  },
  product_id: {
    label: "Price ID",
    type: "text",
    placeholder: "Stripe Price ID",
    defaultValue: "",
    rules: {
      required: true,
    }
  }
};

const venuesForm = {
  name: {
    label: "Venue Name",
    type: "text",
    placeholder: "Name of Venue",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  address_line_1: {
    label: "Address Line 1",
    type: "text",
    placeholder: "Address Line 1",
    defaultValue: "",
    rules: {
      required: true,
    }
  },
  address_line_2: {
    label: "Address Line 2",
    type: "text",
    placeholder: "Address Line 2",
    defaultValue: "",
    rules: {
      required: true,
    }
  },
  city: {
    label: "City",
    type: "text",
    placeholder: "City",
    defaultValue: "",
    rules: {
      required: true,
    }
  },
  country: {
    label: "Country",
    type: "text",
    placeholder: "Country",
    defaultValue: "",
    rules: {
      required: true,
    }
  },
  county: {
    label: "County",
    type: "text",
    placeholder: "County",
    defaultValue: "",
    rules: {
      required: true,
    }
  },
  postcode: {
    label: "Postcode",
    type: "text",
    placeholder: "Postcode",
    defaultValue: "",
    rules: {
      required: true,
    }
  }
};

const creditsForm = {
  recommend: {
    label: "Referral Credit Count",
    type: "text",
    placeholder: "How many credits will members receive per referral",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  maximum_recommend_monthly: {
    label: "Maximum Monthly Referral Credits",
    type: "text",
    placeholder: "Maximum number of credits a member can receive from referrals per month",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
  free_monthly_credit: {
    label: "Free Credit Count",
    type: "text",
    placeholder: "How many free credits will members receive per month",
    defaultValue: "",
    rules: {
      required: true,
    },
  },
};

const Settings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ eventFormatsLoaded, setEventFormatsLoaded ] = useState(false);
  const [ eventFormats, setEventFormats ] = useState([]);
  const [ eventLocationsLoaded, setEventLocationsLoaded ] = useState(false);
  const [ eventLocations, setEventLocations ] = useState([]);
  const [ eventRegionsLoaded, setEventRegionsLoaded ] = useState(false);
  const [ eventRegions, setEventRegions ] = useState([]);
  const [ profileIndustriesLoaded, setProfileIndustriesLoaded ] = useState(false);
  const [ profileIndustries, setProfileIndustries ] = useState([]);
  const [ profileMembershipTypesLoaded, setProfileMembershipTypesLoaded ] = useState(false);
  const [ profileMembershipTypes, setProfileMembershipTypes ] = useState([]);
  const [ venuesLoaded, setVenuesLoaded ] = useState(false);
  const [ venues, setVenues ] = useState([]);
  const [ events, setEvents ] = useState([]);
  const [ eventsLoaded, setEventsLoaded ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
 
  const toast = useToast()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  
  const {
    handleSubmit: handleSubmitInstanceSettings,
    control: controlGlobalSettings,
    watch: watchGlobalSettings,
    register: registerGlobalSettings,
    formState: { errors: errorsGlobalSettings },
  } = useForm({
    defaultValues: async () => {
      const settings = await getConfiguration();
      return settings;
    },
    mode: "onChange"
  });

  const {
    handleSubmit: handleSubmitEventFormats,
    control: controlEventFormats,
    watch: watchEventFormats,
    formState: { errors: errorsEventFormats },
  } = useForm();

  const {
    handleSubmit: handleSubmitEventLocations,
    control: controlEventLocations,
    watch: watchEventLocations,
    formState: { errors: errorsLocations },
  } = useForm();

  const {
    handleSubmit: handleSubmitEventRegions,
    control: controlEventRegions,
    watch: watchEventRegions,
    formState: { errors: errorsRegions },
  } = useForm();

  const {
    handleSubmit: handleSubmitProfileIndustries,
    control: controlProfileIndustries,
    watch: watchProfileIndustries,
    formState: { errors: errorsProfileIndustries },
  } = useForm();

  const { 
    handleSubmit: handleSubmitProfileMembershipTypes,
    control: controlProfileMembershipTypes,
    watch: watchProfileMembershipTypes,
    formState: { errors: errorsProfileMembershipTypes },
  } = useForm();

  const {
    handleSubmit: handleSubmitVenues,
    control: controlVenues,
    watch: watchVenues,
    formState: { errors: errorsVenues },
  } = useForm();

  const {
    handleSubmit: handleSubmitCredits,
    control: controlCredits,
    watch: watchCredits,
    formState: { errors: errorsCredits },
  } = useForm({
    defaultValues: async () => {
      const rules = await getCreditRules();
      return rules;
    },
    mode: "onChange"
  });
  
  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }

      if(!loaded) {
        // get the event formats
        if (!eventFormatsLoaded) {
          getEventFormats().then(formats => {
            setEventFormats(formats);
            setEventFormatsLoaded(true);
          });
        }

        // get the event locations
        if (!eventLocationsLoaded) {
          getEventLocations().then(locations => {
            setEventLocations(locations);
            setEventLocationsLoaded(true);
          });
        }

        // get the event regions
        if (!eventRegionsLoaded) {
          getEventRegions().then(regions => {
            setEventRegions(regions);
            setEventRegionsLoaded(true);
          });
        }

        // get the profile industries
        if (!profileIndustriesLoaded) {
          getProfileIndustries().then(industries => {
            setProfileIndustries(industries);
            setProfileIndustriesLoaded(true);
          });
        }

        // get the profile membership types
        if (!profileMembershipTypesLoaded) {
          getMemberships().then(memberships => {
            setProfileMembershipTypes(memberships);
            setProfileMembershipTypesLoaded(true);
          });
        }

        // venues
        if (!venuesLoaded) {
          getVenues().then(venues => {
            setVenues(venues);
            setVenuesLoaded(true);
          });
        }

        // Events
        if (!eventsLoaded) {
          getEvents().then(events => {
            setEvents(events);
            setEventsLoaded(true);
          });
        }

        setLoaded(true);
      }
    });
  })

  const Input = ({ value, onChange, type, ...rest }) => {
    switch (type) {
      case "text":
        return (
          <TextBoxComponent
            placeholder={rest?.placeholder}
            change={({ value }) => onChange(value)}
            value={value}
          />
        );
      case "number":
          return (
            <NumericTextBoxComponent
              placeholder={rest?.placeholder}
              change={({ value }) => onChange(value)}
              value={value}
            />
          );

      case "textarea":
          return (
            <TextBoxComponent
              multiline={true}
              placeholder={rest?.placeholder}
              change={({ value }) => onChange(value)}
              value={value}
            />
          );

      case "radio":
        return rest?.options.map((e) => (
          <RadioButtonComponent
            key={e}
            label={e}
            value={e}
            onChange={(value) => onChange(value)}
            checked={value === e}
          />
        ));
      case "dropdown":
        return (
          <DropDownListComponent
            dataSource={rest?.options}
            select={({ itemData }) => {
              onChange(itemData.value);
            }}
            value={value}
          />
        );
      case "file":
        return (<>
          { value && value.length > 4 && (<>
              <br />
              <img src={value} alt="logo" style={{ width: 100 }} />
            </>) }
          <input
            type="file"
            onChange={(e) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                onChange(e.target.result);
              };
              reader.readAsDataURL(e.target.files[0]);
            }}
          />
          </>
        );
   
      case "checkbox":
        return (
          <CheckBoxComponent
            label={rest?.checkboxLabel}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        );
   
      default:
        return null;
    }
  };
  
  const formInputs = Object.keys(settingsForm).map((e) => {
    const { rules, defaultValue, label } = settingsForm[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label>{label}</label>
        <Controller
          name={e}
          control={controlGlobalSettings}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...settingsForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  const formatFormInputs = Object.keys(formatsForm).map((e) => {
    const { rules, defaultValue, label } = formatsForm[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label>{label}</label>
        <Controller
          name={e}
          control={controlEventFormats}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...formatsForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  const locationFormInputs = Object.keys(locationsForm).map((e) => {
    const { rules, defaultValue, label } = locationsForm[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label>{label}</label>
        <Controller
          name={e}
          control={controlEventLocations}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...locationsForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  const regionFormInputs = Object.keys(regionsForm).map((e) => {
    const { rules, defaultValue, label } = regionsForm[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label>{label}</label>
        <Controller
          name={e}
          control={controlEventRegions}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...regionsForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  const industryFormInputs = Object.keys(industriesForm).map((e) => {
    const { rules, defaultValue, label } = industriesForm[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label>{label}</label>
        <Controller
          name={e}
          control={controlProfileIndustries}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...industriesForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  const membershipFormInputs = Object.keys(membershipsForm).map((e) => {
    const { rules, defaultValue, label } = membershipsForm[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
      { e === 'event' && (watchProfileMembershipTypes('type') === 'Event' ? <label>{label}</label> : <></>) }
      { e !== 'event' && <label>{label}</label> }
      { (e.name === 'event' && watchProfileMembershipTypes('type') === 'Event') || (e.name !== 'event') && <Controller
        name={e}
        control={controlProfileMembershipTypes}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => field.name === 'event'
        ? ( watchProfileMembershipTypes('type') === 'Event'
          ? (
            <div>
            <Input
              value={field.value}
              onChange={field.onChange}
              {...membershipsForm[e]}
              options={events.map(event => event.name)}
            />
            </div>
          ) : <></>
        )
        : (
          <div>
          <Input
            value={field.value}
            onChange={field.onChange}
            {...membershipsForm[e]}
          />
          </div>
        )
        }
      /> }
      {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  const venueFormInputs = Object.keys(venuesForm).map((e) => {
    const { rules, defaultValue, label } = venuesForm[e];

    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label>{label}</label>
        <Controller
          name={e}
          control={controlVenues}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...venuesForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  const creditInputs = Object.keys(creditsForm).map((e) => {
    const { rules, defaultValue, label } = creditsForm[e];
  
    return (
      <section style={{ marginBottom: 8 }} key={e}>
        <label>{label}</label>
        <Controller
          name={e}
          control={controlCredits}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...creditsForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>This field is required</Error>}
      </section>
    );
  });

  let headerText = [
    { text: "Global Settings" },
    { text: "Formats" },
    { text: "Locations" },
    { text: "Regions" },
    { text: "Venues" },
    { text: "Industries" },
    { text: "Memberships" },
    { text: "Credit Rules" }
  ];

  const onSubmit = (data) => console.log(data);

  const onSubmitGlobalSettings = async (data) => {
    console.log(data);
    await updateConfiguration(data);

    toast({
      title: 'Settings Updated.',
      description: "Global settings have been updated.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmitVenues = async (data) => {
    await createVenue(data);

    getVenues().then(venues => {
      setVenues(venues);
      setVenuesLoaded(true);
    });

    toast({
      title: 'Venue Created.',
      description: "Venue has been created.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmitEventFormats = async (data) => {
    await createEventFormat(data);

    getEventFormats().then(formats => {
      setEventFormats(formats);
      setEventFormatsLoaded(true);
    });

    toast({
      title: 'Format Created.',
      description: "Event format has been created.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmitEventLocations = async (data) => {
    await createEventLocation(data);

    getEventLocations().then(locations => {
      setEventLocations(locations);
      setEventLocationsLoaded(true);
    });

    toast({
      title: 'Location Created.',
      description: "Event location has been created.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmitEventRegions = async (data) => {
    await createEventRegion(data);

    getEventRegions().then(regions => {
      setEventRegions(regions);
      setEventRegionsLoaded(true);
    });

    toast({
      title: 'Region Created.',
      description: "Event region has been created.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmitProfileIndustries = async (data) => {
    await createProfileIndustry(data);

    getProfileIndustries().then(industries => {
      setProfileIndustries(industries);
      setProfileIndustriesLoaded(true);
    });

    toast({
      title: 'Industry Created.',
      description: "Industry has been created.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmitProfileMembershipTypes = async (data) => {
    if(!!data.event) data.event = events.find(event => event.name === data.event).url;
    await createMembership(data);

    const memberships = await getMemberships();
    setProfileMembershipTypes(memberships);
    
    toast({
      title: 'Membership Type Created.',
      description: "Membership type has been created.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const onSubmitCredits = async (data) => {
    saveCreditRules(data);

    toast({
      title: 'Credit Rules Updated.',
      description: "Credit rules have been updated.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  const deleteEventFormatById = async (id) => {
    await deleteEventFormat({ id });

    getEventFormats().then(formats => {
      setEventFormats(formats);
      setEventFormatsLoaded(true);
    });

    toast({
      title: 'Format Deleted.',
      description: "The event format has been deleted.",
      status: 'warning',
      duration: 9000,
      isClosable: true,
    })
  }

  const deleteEventLocationById = async (id) => {
    await deleteEventLocation({ id });

    getEventLocations().then(locations => {
      setEventLocations(locations);
      setEventLocationsLoaded(true);
    });

    toast({
      title: 'Location Deleted.',
      description: "The event location has been deleted.",
      status: 'warning',
      duration: 9000,
      isClosable: true,
    })
  }

  const deleteEventRegionById = async (id) => {
    await deleteEventRegion({ id });
    
    getEventRegions().then(regions => {
      setEventRegions(regions);
      setEventRegionsLoaded(true);
    });

    toast({
      title: 'Region Deleted.',
      description: "The event region has been deleted.",
      status: 'warning',
      duration: 9000,
      isClosable: true,
    })
  }

  const deleteProfileIndustryById = async (id) => {
    const count = profileIndustries.length;
    await deleteProfileIndustry({ id });
    
    getProfileIndustries().then(industries => {
      if(industries.length === count) {
        toast({
          title: 'Error Deleting Industry.',
          description: "The industry could not be deleted. It is likely attached to a profile.",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      setProfileIndustries(industries);
      setProfileIndustriesLoaded(true);

      toast({
        title: 'Industry Deleted.',
        description: "The industry has been deleted.",
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    });
  }

  const deleteProfileMembershipTypeById = async (id) => {
    let memberships = await getMemberships();
    
    await deleteMembership({ id });

    if(await getMemberships().length === memberships.length) {
      toast({
        title: 'Error Deleting Membership Type.',
        description: "The membership type could not be deleted. It is likely attached to a profile.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    } else {
      memberships = await getMemberships();
      setProfileMembershipTypes(memberships);
    }
    
    getMemberships().then(memberships => {
      setProfileMembershipTypes(memberships);

      toast({
        title: 'Membership Type Deleted.',
        description: "The membership type has been deleted.",
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    });
  }

  const deleteVenueById = async (id) => {
    const count = venues.length;
    await deleteVenue({ id });

    getVenues().then(venues => {
      if(venues.length === count) {
        toast({
          title: 'Error Deleting Venue.',
          description: "The venue could not be deleted. It is likely attached to an event.",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      } else {
        toast({
          title: 'Venue Deleted.',
          description: "The venue has been deleted.",
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })

        getVenues().then(venues => {
          setVenues(venues);
          setVenuesLoaded(true);
        });
      }
    });
  }

  const Error = ({ children }) => <p style={{ color: "red" }}>{children}</p>;

  const globalSettings = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Global Settings</Heading>
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitInstanceSettings(onSubmitGlobalSettings)}>
          {/* render the form inputs */}
          {formInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Save
            </ButtonComponent>
          </div>
        </form>
      </div>
    }
  </Box>)

  const formats = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Formats</Heading>  
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitEventFormats(onSubmitEventFormats)}>
          {/* render the form inputs */}
          {formatFormInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Add Format
            </ButtonComponent>
          </div>
        </form>
      </div>
    }

    { !eventFormatsLoaded && <p>Loading...</p> }

    { !eventFormatsLoaded && eventFormats.length === 0 && <p>No event formats.</p> }

    { eventFormatsLoaded && eventFormats.length > 0 && <TableContainer mt={8}>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Format Name</Th>
            <Th>Description</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          { eventFormats.map(({name, description, url}) => (
            <Tr key={url}>
              <Td>{name}</Td>
              <Td>{description}</Td>
              <Td><ButtonComponent type="submit" cssClass="e-danger" onClick={() => deleteEventFormatById(url.split('/')[url.split('/').length - 2])}>Delete</ButtonComponent></Td>
            </Tr>
          )) }
        </Tbody>
      </Table>
    </TableContainer> }
  </Box>)

  const locations = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Locations</Heading>  
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitEventLocations(onSubmitEventLocations)}>
          {/* render the form inputs */}
          {locationFormInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Add Location
            </ButtonComponent>
          </div>
        </form>
      </div>
    }

    { !eventLocationsLoaded && <p>Loading...</p> }

    { !eventLocationsLoaded && eventLocations.length === 0 && <p>No event locations.</p> }

    { eventLocationsLoaded && eventLocations.length > 0 && <TableContainer mt={8}>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Location Name</Th>
            <Th>Address</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          { eventLocations.map(({name, description, url}) => (
            <Tr key={url}>
              <Td>{name}</Td>
              <Td>{description}</Td>
              <Td><ButtonComponent type="submit" cssClass="e-danger" onClick={() => deleteEventLocationById(url.split('/')[url.split('/').length - 2])}>Delete</ButtonComponent></Td>
            </Tr>
          )) }
        </Tbody>
      </Table>
    </TableContainer> }
  </Box>)

  const regions = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Regions</Heading>  
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitEventRegions(onSubmitEventRegions)}>
          {/* render the form inputs */}
          {regionFormInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Add Region
            </ButtonComponent>
          </div>
        </form>
      </div>
    }

    { !eventRegionsLoaded && <p>Loading...</p> }

    { !eventRegionsLoaded && eventRegions.length === 0 && <p>No event regions.</p> }

    { eventRegionsLoaded && eventRegions.length > 0 && <TableContainer mt={8}>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Region Name</Th>
            <Th>Description</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          { eventRegions.map(({name, description, url}) => (
            <Tr key={url}>
              <Td>{name}</Td>
              <Td>{description}</Td>
              <Td><ButtonComponent type="submit" cssClass="e-danger" onClick={() => deleteEventRegionById(url.split('/')[url.split('/').length - 2])}>Delete</ButtonComponent></Td>
            </Tr>
          )) }
        </Tbody>
      </Table>
    </TableContainer> }
  </Box>)

  const venuesMarkup = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Venues</Heading>
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitVenues(onSubmitVenues)}>
          {/* render the form inputs */}
          {venueFormInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Add Venue
            </ButtonComponent>
          </div>
        </form>
      </div>
    }

    { !venuesLoaded && <p>Loading...</p> }

    { !venuesLoaded && venues.length === 0 && <p>No Venues.</p> }

    { venuesLoaded && venues.length > 0 && <TableContainer mt={8}>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Venue Name</Th>
            <Th>Address</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          { venues.map(({name, address_line_1, address_line_2, city, country, county, postcode, url}) => (
            <Tr key={url}>
              <Td>{name}</Td>
              <Td style={{ maxWidth: 250, overflow: 'clip' }}>{address_line_1}, {address_line_2}, {city}, {county}, {country}, {postcode}</Td>
              <Td><ButtonComponent type="submit" cssClass="e-danger" onClick={() => deleteVenueById(url.split('/')[url.split('/').length - 2])}>Delete</ButtonComponent></Td>
            </Tr>
          ) )}
        </Tbody>
      </Table>
    </TableContainer> }
  </Box>)

  const industries = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Industries</Heading>  
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitProfileIndustries(onSubmitProfileIndustries)}>
          {/* render the form inputs */}
          {industryFormInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Add Industry
            </ButtonComponent>
          </div>
        </form>
      </div>
    }

    { !profileIndustriesLoaded && <p>Loading...</p> }

    { !profileIndustriesLoaded && profileIndustries.length === 0 && <p>No Industries.</p> }

    { profileIndustriesLoaded && profileIndustries.length > 0 && <TableContainer mt={8}>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Industry Name</Th>
            <Th>Description</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          { profileIndustries.map(({name, description, url}) => (
            <Tr key={url}>
              <Td>{name}</Td>
              <Td>{description}</Td>
              <Td><ButtonComponent type="submit" cssClass="e-danger" onClick={() => deleteProfileIndustryById(url.split('/')[url.split('/').length - 2])}>Delete</ButtonComponent></Td>
            </Tr>
          ) )}
        </Tbody>
      </Table>
    </TableContainer> }
  </Box>)

  const memberships = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Memberships</Heading>  
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitProfileMembershipTypes(onSubmitProfileMembershipTypes)}>
          {/* render the form inputs */}
          {membershipFormInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Add Membership Type
            </ButtonComponent>
          </div>
        </form>
      </div>
    }

    { !profileMembershipTypesLoaded && <p>Loading...</p> }

    { !profileMembershipTypesLoaded && profileMembershipTypes.length === 0 && <p>No Membership Types.</p> }

    { profileMembershipTypesLoaded && profileMembershipTypes.length > 0 && <TableContainer mt={8}>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Membership Name</Th>
            <Th>Description</Th>
            <Th>Monthly Price</Th>
            <Th>Monthly Credits</Th>
            <Th>Stripe Price ID</Th>
            <Th>Event</Th>
            <Th>&nbsp;</Th>
          </Tr>
        </Thead>
        <Tbody>
          { profileMembershipTypes.map(({name, description, cost, monthly_credits, product_id, url, event}) => (
            <Tr key={url}>
              <Td>{name}</Td>
              <Td>{description}</Td>
              <Td>{cost}</Td>
              <Td>{monthly_credits}</Td>
              <Td>{product_id}</Td>
              <Td>{events.find(e => e.url === event)?.name}</Td>
              <Td><ButtonComponent type="submit" cssClass="e-danger" onClick={() => deleteProfileMembershipTypeById(url.split('/')[url.split('/').length - 2])}>Delete</ButtonComponent></Td>
            </Tr>
          ) )}
        </Tbody>
      </Table>
    </TableContainer> }
  </Box>)

  const creditRules = () => (<Box p={4} m={4}>
    <Heading as='h3' size='md' mb={4}>Credit Rules</Heading>
    {
      <div className="wrapper">
        <form onSubmit={handleSubmitCredits(onSubmitCredits)}>
          {/* render the form inputs */}
          {creditInputs}
          <div style={{ textAlign: "center" }}>
            <ButtonComponent type="submit" cssClass="e-success">
              Save
            </ButtonComponent>
          </div>
        </form>
      </div>
    }
  </Box>)

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bgGradient='linear(to-b, gray.100, white)'>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Heading as='h3' size='lg' mb={4}>Settings</Heading>
          <TabComponent heightAdjustMode='Auto'>
            <TabItemsDirective>
              <TabItemDirective header={headerText[0]} content={globalSettings}/>
              <TabItemDirective header={headerText[1]} content={formats}/>
              <TabItemDirective header={headerText[2]} content={locations}/>
              <TabItemDirective header={headerText[3]} content={regions}/>
              <TabItemDirective header={headerText[4]} content={venuesMarkup}/>
              <TabItemDirective header={headerText[5]} content={industries}/>
              <TabItemDirective header={headerText[6]} content={memberships}/>
              <TabItemDirective header={headerText[7]} content={creditRules}/>
              {/* <TabItemDirective header={headerText[6]} content={coupons}/> */}
            </TabItemsDirective>
          </TabComponent>
        </Box>
      </Box>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </ChakraProvider>
  )
}

export default Settings