import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Select,
  Checkbox,
  Textarea,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, TrendlineDirective, TrendlinesDirective, Inject, Tooltip, LineSeries, ScatterSeries, SplineSeries, Trendlines, Category, DateTime } from '@syncfusion/ej2-react-charts';

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { getMarketingStatus } from './lib/marketing_status';
import { isLoggedIn } from './lib/authentication';
import { getProfiles } from './lib/profile';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { getEventInstances, getEvents } from './lib/events';
import { checkConflict, createConflictException, deleteConflictException } from './lib/conflict';
import { TextArea } from '@syncfusion/ej2-react-inputs';

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ attendees, setAttendees ] = useState([]);
  const [ eventInstances, setEventInstances ] = useState([]);
  
  const [selectedAttendee, setSelectedAttendee] = useState('');
  const [selectedEventInstance, setSelectedEventInstance] = useState('');
  const [conflicts, setConflicts] = useState([]);
  const [conflictMessage, setConflictMessage] = useState('');

  const [resolved, setResolved] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [savedConflict, setSavedConflict] = useState(null);

  const [ loaded, setLoaded ] = useState(false);

  const handleDescribeConflicts = async () => {
    const conflicts = await checkConflict({profile_id: selectedAttendee, event_instance_id: selectedEventInstance});
    setConflicts(conflicts);

    setConflictMessage(conflicts.length ? 'Conflicts found. Please resolve.' : 'No conflicts found.');
  };

  const handleSaveConflict = () => {
    setSavedConflict({
      attendee: attendees.find(a => a.id === parseInt(selectedAttendee)).name,
      event: eventInstances.find(e => e.id === parseInt(selectedEventInstance)).name,
      resolved,
      additionalInfo
    });
    setConflictMessage('Conflict resolved.');
  };
  
  const [statusLoaded, setStatusLoaded] = useState(false);

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    if(!loaded) {
      getProfiles().then(profiles => {
        setAttendees(profiles);
      });

      getEvents().then(events => {  
        getEventInstances().then(eventInstances => {
          setEventInstances(eventInstances.map((eventInstance) => ({
            ...eventInstance,
            event: events.find(e => e.id === eventInstance.event)
          })));
        });
      });

      setLoaded(true);
    }
  }, [loaded, attendees]);

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Heading as='h3' size='lg' mb={4}>Conflict Manager</Heading>
          <Box>
            <VStack spacing={4} mb={16} align="stretch">
              <Box>
                <Text mb="8px">Select Attendee:</Text>
                <DropDownListComponent
                  placeholder="Select an attendee"
                  dataSource={attendees.map((attendee) => ({
                    id: attendee.url.split('/')[attendee.url.split('/').length - 2],
                    text: attendee.name }))}
                  value={selectedAttendee}
                  allowFiltering
                  onChange={(e) => {
                    setSelectedAttendee(e.target.itemData.id);
                  }} />
              </Box>
              <Box>
                <Text mb="8px">Select Event Instance:</Text>
                <DropDownListComponent
                  placeholder="Select an event instance"
                  dataSource={eventInstances.map((eventInstance) => ({
                    id: eventInstance.url.split('/')[eventInstance.url.split('/').length - 2],
                    text: `${eventInstance.event.name} - ${eventInstance.name} - ${moment(eventInstance.start).format('MM/DD/YYYY hh:mm A')} - ${eventInstance.url.split('/')[eventInstance.url.split('/').length - 2]}`
                  }))}
                  value={selectedEventInstance}
                  allowFiltering
                  onChange={(e) => {
                    console.log({
                      id: e.target.itemData.id
                    });
                    setSelectedEventInstance(e.target.itemData.id);
                  }} />
              </Box>
              <Button colorScheme="teal" onClick={handleDescribeConflicts}>Describe Conflicts</Button>
                {JSON.stringify(conflicts)}
                {conflicts.length == 0 && conflictMessage && (
                  <Box mt={4}>
                    <Text>{conflictMessage}</Text>
                    {conflictMessage.includes('Conflict with') && (
                      <Box mt={4}>
                        <Checkbox backgroudColor='white' isChecked={resolved} onChange={(e) => setResolved(e.target.checked)}>
                          Mark as resolved
                        </Checkbox>
                        {resolved && (<>
                          <Textarea
                            backgroundColor='white'
                            mt={2}
                            placeholder="Additional information"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                          />
                          <Button mt={2} colorScheme="teal" onClick={handleSaveConflict}>
                            Submit
                          </Button>
                        </>)}
                      </Box>
                    )}
                    {conflictMessage.includes('Too many other attendees') && (
                      <Box mt={4}>
                        <Checkbox backgroudColor='white' isChecked={resolved} onChange={(e) => setResolved(e.target.checked)}>
                          Mark as resolved
                        </Checkbox>
                        {resolved && (<>
                          <Textarea
                            backgroundColor='white'
                            mt={2}
                            placeholder="Additional information"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                          />
                          <Button mt={2} colorScheme="teal" onClick={handleSaveConflict}>
                            Submit
                          </Button>
                        </>)}
                      </Box>
                    )}
                    {savedConflict && (
                      <Box mt={4}>
                        <Text>Conflict Details:</Text>
                        <Text>Attendee: {savedConflict.attendee}</Text>
                        <Text>Event: {savedConflict.event}</Text>
                        <Text>Resolved: {savedConflict.resolved ? 'Yes' : 'No'}</Text>
                        <Text>Additional Info: {savedConflict.additionalInfo}</Text>
                      </Box>
                    )}
                  </Box>
                )}
                { conflicts.filter(c => !c.resolved).length > 0 && (
                  <Box mt={4} mb={6} style={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'red',
                    padding: '8px',
                    backgroundColor: 'lightcoral',
                    textAlign: 'center'
                  }}>
                    <Text><strong>Unable to attend. Conflicts below.</strong></Text>
                  </Box>
                )}
                { conflictMessage.length > 0 && conflicts.filter(c => !c.resolved).length === 0 && (
                  <Box mt={4} mb={6} style={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'green',
                    padding: '8px',
                    backgroundColor: 'lightgreen',
                    textAlign: 'center'
                  }}>
                    <Text><strong>Attendee may attend.</strong></Text>
                  </Box>
                )}
                {
                  conflicts.length > 0 && conflicts.map((conflict) => (
                    <Box key={conflict.id}>
                      <Text><strong>{conflict.description}</strong></Text>
                      { conflict.resolved && (<>
                        <Text>Resolved</Text>
                        <Text>{conflict?.explanation || '...'}</Text>
                        <Button colorScheme="teal" float={"right"} size={"sm"} mt={2} onClick={async () => {
                          deleteConflictException({ guid: conflict.guid });
                          const conflicts = await checkConflict({profile_id: selectedAttendee, event_instance_id: selectedEventInstance});
                          setConflicts(conflicts);
                        }}>Delete Conflict Exception</Button>
                      </>)}
                      {!conflict.resolved && (<>
                        <Textarea
                          mt={2} 
                          placeholder="Details of Exception"
                          value={conflict.explanation}
                          onChange={(e) => setConflicts(conflicts.map(c => c.guid === conflict.guid ? { ...c, explanation: e.target.value } : c))}
                          style={{
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'gray',
                          }}
                          />
                        <Button colorScheme="teal" float={"right"} size={"sm"} mt={2} onClick={async () => {
                          createConflictException({ guid: conflict.guid, explanation: conflict.explanation });
                          const conflicts = await checkConflict({profile_id: selectedAttendee, event_instance_id: selectedEventInstance});
                          setConflicts(conflicts);
                        }}>
                          Resolve, with Exception
                        </Button>
                      </>)}
                    </Box>
                  ))
                }
            </VStack>
          </Box>
          <Box mt={4}></Box>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default App