import { globalSettings } from "./configuration";
import { getAuthToken, setAuthToken, removeAuthToken } from "./browser_state";

const base_url = globalSettings.PORTAL_API_URL;

export const checkConflict = async ({profile_id, event_instance_id}) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/v1/check_conflicts?profile_id=${profile_id}&event_instance_id=${event_instance_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })

    const conflicts = await response.json();

    return conflicts.data;
}

export const getConflictException = async ({ guid }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_conflict_exceptions/?guid=${guid}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })

    const explanation = await response.json();

    return explanation;
}

export const createConflictException = async ({ guid, explanation }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    headers.append("Content-Type", "application/json");

    const response = await fetch(`${base_url}/api/profile_conflict_exceptions/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            guid,
            description: explanation,
            resolved: true
        }),
        redirect: 'follow'
    })

    return response;
}

export const deleteConflictException = async ({ guid }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_conflict_exceptions/${guid}/`, {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    })

    return response;
}
