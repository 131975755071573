import { getAuthToken } from './browser_state';
import { getProfile } from './profile';

let PORTAL_API_URL = '{{ settings.PORTAL_API_URL }}';
let MANAGEMENT_API_URL = '{{ settings.MANAGEMENT_API_URL }}';

export const globalSettings = {
    PORTAL_API_URL: PORTAL_API_URL == '{{ settings.PORTAL_API_URL }}' ? 'https://eventra.fly.dev' : PORTAL_API_URL,
    MANAGEMENT_API_URL: MANAGEMENT_API_URL == '{{ settings.MANAGEMENT_API_URL }}' ? 'https://eventra-manager.fly.dev' : MANAGEMENT_API_URL,
}

if(typeof process.env.REACT_APP_DOCKER_DEV !== 'undefined' && process.env.REACT_APP_DOCKER_DEV == 'true') {
    PORTAL_API_URL = process.env.REACT_APP_PORTAL_API_URL;
    MANAGEMENT_API_URL = process.env.REACT_APP_MANAGEMENT_API_URL;
    
    globalSettings['PORTAL_API_URL'] = PORTAL_API_URL;
    globalSettings['MANAGEMENT_API_URL'] = MANAGEMENT_API_URL;
}

if (document.location.href.indexOf('dashboard.selwyn.digper.tech') > -1) {
    globalSettings['PORTAL_API_URL'] = 'https://api.selwyn.digper.tech';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwyn.digper.tech';
}

if (document.location.href.indexOf('dashboard.selwynevents.com') > -1) {
    globalSettings['PORTAL_API_URL'] = 'https://api.selwynevents.com';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwynevents.com';
}

if (document.location.href.indexOf('localhost') > -1) {
    globalSettings['PORTAL_API_URL'] = 'http://localhost:8000';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwyn.digper.tech';
    globalSettings['PORTAL_API_URL'] = 'https://api.selwyn.digper.tech';
    globalSettings['MANAGEMENT_API_URL'] = 'https://licensing.selwyn.digper.tech';
}

const base_url = globalSettings.PORTAL_API_URL;

let configuration_cache = false;
export const getConfiguration = async () => {
    if(!!configuration_cache) return configuration_cache;
    
    const base_url = globalSettings.PORTAL_API_URL;
    const configuration_url = `${base_url}/manager/configuration`;
    const config = await fetch(configuration_url);
    const config_struct =  await config.json();

    configuration_cache = config_struct;
    return configuration_cache;
}

export const updateConfiguration = async ({
    name,
    logo,
    header_logo,
    description,
    email,
    primary_color,
    secondary_color,
    registration_enabled,
    terms,
    portal_url,
    require_coupon,
    custom_css,
    custom_js,
}) => {
    const profile = await getProfile();
    const profileId = profile.url.split('/')[profile.url.split('/').length - 2];

    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);
    
    const formdata = new FormData();
    if(!!name) formdata.append("name", name);
    if(!!description) formdata.append("description", description);
    if(!!email) formdata.append("email", email);
    if(!!primary_color) formdata.append("primary_color", primary_color);
    if(!!secondary_color) formdata.append("secondary_color", secondary_color);
    if(typeof registration_enabled != 'undefined') {
        if(registration_enabled == true) {
            formdata.append("registration_enabled", true);
        } else {
            formdata.append("registration_enabled", false);
        }
    }
    if(!!portal_url) formdata.append("portal_url", portal_url);
    if(!!terms) formdata.append("terms", terms);
    if(typeof require_coupon != 'undefined') {
        if(require_coupon == true) {
            formdata.append("require_coupon", true);
        } else {
            formdata.append("require_coupon", false);
        }
    }
    if(!!custom_css) formdata.append("custom_css", custom_css);
    if(!!custom_js) formdata.append("custom_js", custom_js);
    if(!!logo && logo.split(':')[0] == 'data') formdata.append("logo", logo);
    if(!!header_logo && header_logo.split(':')[0] == 'data') formdata.append("header_logo", header_logo);
    
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/manager/configuration`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}
