import { getAuthToken } from './browser_state';
import { getProfile } from './profile';
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const getPages = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/pages/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const pages = await response.json();

    return pages.results;
}

export const createPage = async ({ title, content }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const body = new FormData();
    body.append("title", title);
    body.append("content", content);

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/pages/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const updatePage = async ({ id, title, content }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const body = new FormData();
    body.append("title", title);
    body.append("content", content);

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/pages/${id}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}

export const deletePage = async ({ id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${base_url}/api/pages/${id}/`, requestOptions);
        const data = await response.json();

        return data;
    }
    catch (error) {
        console.error('Error:', error);
        return false;
    }
}
