import { getAuthToken } from './browser_state';
import { getProfile } from './profile';
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const getMemberships = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/memberships/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const memberships = await response.json();

    return memberships.results;
}

export const getCurrentMemberships = async ({ profile_id }) => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/profile_memberships/?profile_id=${profile_id}`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const memberships = await response.json();

    return memberships;
}
