import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Heading,
  Button,
  Select,
  Input,
  Checkbox,
  Textarea,
  theme
} from '@chakra-ui/react'

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'

import moment from 'moment';

import { SidebarContent, NavItem, MobileNav } from './components/Layout';

import { isLoggedIn } from './lib/authentication';
import { getConfiguration } from './lib/configuration';
import { getPages, createPage, updatePage, deletePage } from './lib/cms';

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const [ globalSettings, setGlobalSettings ] = useState({
    portal_url: '',
  });
  const [ pages, setPages ] = useState([]);
  const [ selectedPage, setSelectedPage ] = useState({
    title: '',
    content: ''
  });
  
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if(!loggedIn) {
        window.location.href = '/login';
      }
    });

    if(!loaded) {
      getConfiguration().then(settings => {
        setGlobalSettings(settings);
      });

      getPages().then(pages => {
        console.log(pages);
        setPages(pages);
        setLoaded(true);
      });
    }
  })

  const updatePageContent = ({ id, title, content }) => {
    updatePage({ id, title, content }).then(() => {
      getPages().then(pages => {
        setPages(pages);
      });
    });
  }

  const deletePageItem = ({ id }) => {
    deletePage({ id }).then(() => {
      getPages().then(pages => {
        setPages(pages);
      });
    });
  }

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Heading as='h3' size='lg' mb={4}>CMS Pages</Heading>
          <Box>
            <VStack spacing={4} align="stretch">
              <Box>
                {pages.map(page => (
                  <Box key={page.id} p={5} shadow="md" borderWidth="1px">
                    <Heading size="">{page.title}</Heading>
                    <Text><a href={`${globalSettings.portal_url}pages/${page.guid}`} target="_blank">{`${globalSettings.portal_url}pages/${page.guid}`}</a></Text>
                    <Text mt={4}>{page.content.slice(0, 100)}...</Text>
                    <Button color={"white"} colorScheme="blue" mt={4} onClick={() => setSelectedPage(page)}>Edit</Button>
                    <Button mt={4} ml={2} colorScheme="red" onClick={() => deletePageItem({ id: page.id })}>Delete</Button>
                  </Box>
                ))}
                <Box p={5} shadow="md" borderWidth="1px">
                  <Heading fontSize="xl">Create New Page</Heading>
                  <Input
                    placeholder="Title"
                    value={selectedPage?.title || ''}
                    onChange={(e) => setSelectedPage({ ...selectedPage, title: e.target.value })}
                    mt={4}
                    background={{
                      base: 'white',
                    }}
                  />
                  <Textarea
                    placeholder="Content"
                    value={selectedPage?.content || ''}
                    onChange={(e) => setSelectedPage({ ...selectedPage, content: e.target.value })}
                    mt={4}
                    background={{
                      base: 'white',
                    }}
                  />
                  <br />
                  <Button colorScheme="green" mt={4} onClick={() => {
                    if (selectedPage.title.length < 3) {
                      alert('Title is required');
                      return;
                    }
                    if (selectedPage.id) {
                      updatePageContent(selectedPage);
                      alert('Page updated');
                    } else {
                      createPage(selectedPage).then(() => {
                        setSelectedPage(null);
                        getPages().then(pages => setPages(pages));
                        alert('Page created');
                      });
                    }
                  }}>
                    {selectedPage?.id ? 'Update Page' : 'Create Page'}
                  </Button>
                </Box>
              </Box>
            </VStack>
          </Box>
          <Box mt={4}></Box>
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default App