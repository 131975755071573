import { getAuthToken } from './browser_state';
import { globalSettings } from "./configuration";

const base_url = globalSettings.PORTAL_API_URL;

export const getIndustryDictionary = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/dictionary_industry/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const industries = await response.json();

    return industries.results;
}

export const getMarketingPreferenceDictionaty = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/dictionary/marketing_preference/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const prefs = await response.json();

    return prefs.results;
}

export const getSubscriptionTypesDictionary = async () => {
    const authToken = await getAuthToken();

    const headers = new Headers();
    headers.append("Authorization", `Token ${authToken}`);

    const response = await fetch(`${base_url}/api/dictionary/subscription_types/`, {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    })
    
    const types = await response.json();

    return types.results;
}
